html {
	font-size: 62.5%; // 10px pour faciliter la conversion px vers rem. Ainsi, 10px = 1rem
}

body {
	@extend %text;
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@include gridle_state(md) {
		overflow-x: initial;
	}
}

.ReactModal__Body--open {
	overflow-y: hidden; // desactivation du scroll de la page quand la react-modal est ouverte
}

a {
	@extend %text;
	text-decoration: underline;
}

strong,
b {
	@extend %font-bold;
	display: inline;
}

em {
	font-style: italic;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
}

input[type="number"] {
	-moz-appearance: textfield;
}

button,
video {
	outline: 0;
}