@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.header {
	&__sticky-container {
		position: sticky;
		top: 0;
		z-index: 4;
		background: #eff0f4;
		boxshadow: rgba(0, 0, 0, 0.05) 0px 3px 5px 0px;

		.header__submenu__back {
			display: flex;
			white-space: nowrap;

			.relative-link {
				height: 100%;
			}

			.icon {
				width: 15px;
				height: 15px;
				margin: 0;
			}
		}

		@include gridle_state(md) {
			.header__submenu {
				width: 100%;
			}

			.header__submenu__back {
				display: none;
			}
		}
	}

	&__submenu {
		background: white;
		z-index: 3;
		display: flex;
		justify-content: center;
		height: 30px;
		align-items: center;
		margin: 0 auto;

		@include gridle_state(md) {
			height: 35px;
			width: 1024px;
			transition: width 0.3s ease-in-out;
		}

		&__back {
			display: none;
			height: 70%;
			text-transform: uppercase;
			border-right: 2px solid $grey-dark;
			padding: 0 14px;
		}

		&__scroll-menu {
			height: 100%;
			white-space: nowrap;
			overflow: auto;
			overflow-y: hidden;

			&__item {
				height: 100%;
				display: inline-flex;
				text-align: center;
				margin: 0 14px;
				text-decoration: none;
				align-items: center;
				text-transform: uppercase;
				box-sizing: border-box;

				&:hover {
					color: #737373;
				}

				&--selected {
					color: $color-tertiary;
					border-bottom: 4px solid $color-tertiary;

					&:hover {
						color: $color-tertiary;
					}
				}
			}
		}
	}
}

@media print {
	@page {
		size: 21cm 29.7cm;
		margin: 40px 20px;
	}

	.header__back {
		display: none;
	}
}
