/***************************
 * = COULEURS
 ***************************/

$color-primary: #8c8c8c;
$color-primary-dark: #5c5858;
$color-primary-light: #cbc7c7;
$color-secondary: #598fdc;
$color-secondary-dark: #4773b1;
$color-secondary-light: #7ab1ff;
$color-tertiary: #b81934;
$color-tertiary-dark: #941228;
$color-tertiary-light: #f22e4f;
$black: #2f2f2f;
$grey-dark: #8c8c8c;
$grey-light: #eff0f4;
$grey-medium: #d3d2d3;
$color-error: #a94442;
$color-valid: #0abf74;
$color-info: #3493dc;

/***************************
 * = FONTS
 ***************************/

%beautify {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%font-regular {
	@extend %beautify;
	font-family: "opensans", sans-serif;
}

%font-bold {
	@extend %beautify;
	font-family: "opensans-bold", sans-serif;
}

// Gros titre (nombre de vente sur listing) et Votre Hotel sur la page listing
%h1 {
	@extend %beautify;
	font-family: "playfairdisplay", sans-serif;
	font-size: 2.5rem;
	letter-spacing: 1px;
	text-align: center;

	@include gridle_state(md) {
		letter-spacing: 2.5px;
	}
}

// Chambres, restaurants, lifestyle, Bon savoir sur la fiche produit
%h2 {
	@extend %font-bold;
	font-size: 2.1rem;
}

// nom des chambres et restaurants (titre des photos)
%h3 {
	@extend %font-bold;
	font-size: 1.8rem;

	@include gridle_state(md) {
		font-size: 2rem;
	}
}

// details de cette offre, prix de la quote et paiement
// label des radio button
// titre des label du moteur de recherche
%h4 {
	@extend %font-bold;
	font-size: 1.6rem;
}

%subheading {
	@extend %font-bold;
	font-size: 1.5rem;

	@include gridle_state(md) {
		font-size: 3rem;
	}
}

%text {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $black;
}

%text-secondary {
	@extend %font-regular;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%text-secondary-bold {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $grey-dark;
}

%headline {
	@extend %font-bold;
	font-size: 17px;
	line-height: 1.23;
	letter-spacing: 2.2px;
	color: white;
}

%link {
	@extend %font-bold;
	font-size: 1.4rem;
	line-height: 1.5;
	color: $color-tertiary;
	cursor: pointer;
	text-decoration: none;

	@include gridle_state(md) {
		&:hover {
			text-decoration: underline;
		}
	}
}

%master-button {
	text-transform: none;
	border-radius: 5px;
}

/***************************
 * = COMMUN
 ***************************/

$border-radius-small: 0;
$border-radius-big: 0;
$border-radius-top: 0 0 0 0;
$border-radius-bottom: 0 0 $border-radius-big $border-radius-big;
$amount-color: $color-tertiary;
$header-background-color: white;
$header-border-bottom: 1px solid $grey-medium;
$header-menu-active-color: $black;
$header-text-color: $black;
$header-text-transform: uppercase;

$auth-header-background-color: white;
$auth-header-mobile-background-color: $auth-header-background-color;
$auth-header-text-color: $black;
$auth-header-mobile-text-color: $auth-header-text-color;

$cookie-policy-footer-background: $black;
$cookie-policy-footer-color: white;
$cookie-policy-footer-text-transform: uppercase;
$phone-number-color: $color-tertiary;
$common-box-shadow: none;

$badge-background-color: $color-secondary;

$payment-banner-background-color: $color-tertiary;

/***************************
 * = FORM
 ***************************/

$active-color: $color-secondary;
$active-color-dark: $color-secondary-dark;
$active-color-light: $color-secondary-light;
$input-border-color: $grey-medium;
$input-border: 2px solid $active-color;
$input-border-radius: 5px;
$radio-checked-background: $grey-light;
$radio-hover-border-color: $active-color-light;
$radio-outline: none;
$radio-outline-error: none;
$input-shadow-box: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
$button-primary-background-color: $color-tertiary;
$button-primary-background-color-dark: $color-tertiary-dark;
$button-box-shadow: none;

/***************************
 * = FICHE PRODUIT
 ***************************/
$product-accommodation-text-transform: uppercase;

$deal-background-color: rgba($color-tertiary, 0.9);
$product-deal-text-color: white;
$product-deal-background-color: rgba($color-tertiary, 0.9);

$offer-mobile-background-color: $color-primary;

$bloc-contact-title-case: uppercase;
$bloc-contact-body-background: $black;
$bloc-contact-text-color: white;

$product-aside-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);

$datepicker-background-color: $color-primary;

/***************************
 * = MOTEUR DE RECHERCHE
 ***************************/

$search-engine-offer-color: $black;

/***************************
 * = TUNNEL DE REZA | COMMUN
 ***************************/
$booking-bloc-title-main-background-color: $black;
$booking-amount-upgrade-price-color: $black;
$booking-item-margin: 15px;
$booking-cta-background-color: #0abf74;

$quotation-price-detail-toggle-button-background: $black;
$quotation-price-detail-total-background: $black;
$quotation-price-detail-total-border: 1px solid $black;
$quotation-price-detail-total-font-color: white;
$quotation-price-detail-pay-now-border: 1px solid white;
$quotation-price-detail-text-color: white;
$quotation-price-detail-total-margin-bottom: 10px;

/***************************
 * = TUNNEL DE REZA | QUOTE
 ***************************/
$booking-badge-background-color: $color-secondary;
$badge-font-color: white;
$badge-border: none;
$badge-border-radius: 40px;

$quotation-filter-sort-background: white;

$flight-filter-button-background-color: $grey-light;
$flight-filter-button-color: $black;
$flight-filter-button-border-radius: 50px;
$flight-filter-background-color: $active-color;

$quotation-luggage-included-border-radius: $border-radius-small;
$quotation-luggage-included-outline: unset;

$quotation-cabins-margin: 14px;
$quotation-cabins-filter-header-background: $grey-light;
$quotation-cabins-filter-header-border: none;
$quotation-cabins-filter-border-bottom: none;

/***************************
 * = TUNNEL DE REZA | PAYMENT
 ***************************/
$insurances-radio-first-color: #b81934;
$insurances-radio-second-color: #8c8c8c;

/***************************
 * = TUNNEL DE REZA | CONFIRMATION
 ***************************/
$booking-confirmation-reference-color: $color-tertiary;

/***************************
 * = LISTING
 ***************************/
$listing-background-color: $grey-light;
$product-cta-background-color: $button-primary-background-color;
$product-cta-border: 1px solid $button-primary-background-color;
$product-cta-button-radius: none;
$product-footer-border: none;
$product-deal-public-price-line-through-color: $black;
$product-name-text-transform: none;
$product-intemporelle-color: $color-primary;
$listing-actionbar-title-display-on-mobile: block;
$listings-product-background-color: $grey-light;
$listing-header-background-color: $grey-light;
$colored-product-badge-background-color: white;
$colored-product-badge-text-transform: uppercase;
$colored-product-badge-border-radius: 0;
$merchandising-simple-width: 100%;
$listing-time-border-radius: 50px;
$listing-time-background-color: $black;

/***************************
 * = MERCHANDISING
 ***************************/
$merchandising-listing-background-color: $grey-light;

/***************************
 * = PLACEHOLDERS
 ***************************/

%booking-title {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	box-sizing: border-box;
	text-align: center;
	min-height: 40px;
	padding: 10px 0;
}

%input-label-touched {
	position: absolute;
	top: 0;
	left: 5px;
	font-size: 1rem;
	color: $active-color;
	background: none;
	padding-right: 10px;
	padding-left: 10px;
}

$mobile-auth-background-color: #27c8ee;
$listing-header-filter-max-width: none;
$listing-header-sticky-container-box-shadow: none;
$product-filter-header-box-shadow: none;
$products-filter-selected-border: none;
$products-master-filter-box-shadow: unset;
$aside-product-filters-menu-filters-title-height: 40px;

$marketing-slide-small-logo: url("/static/icons/icon-marketing-slide-small-logo-af.svg");
$marketing-slide-small-1: url("/static/icons/icon-marketing-slide-small-af-1.svg");
