@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.password-policy {
    &__list {
        display: flex;
        flex-direction: column;
        padding: 0 10px 10px;
        max-height: 0;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        &--visible {
            max-height: 1000px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
    }
    
    &__icon {
        display: flex;
        align-items: center;
        padding: 5px;

        &--valid {
            color: $color-valid;
        }

        .icon,
        svg {
            width: 12px;
            height: 12px;
        }
    }

    &__label {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
}